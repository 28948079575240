#AppContainer {
  margin: -20px 5vw;
  padding: 0px 2vw;
}

#AppContainer a {
  color: black !important;
}

#AppContainer a:hover {
  color: #8994a0 !important;
  text-decoration: none;
}

.page-container {
  text-align: center;
  justify-content: center;
  padding-top: 50px;
  font-family: "Roboto Slab", serif;
}

#footer {
  height: 100px;
}

/* Header Styling */

.header-container {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0;
  align-items: center;
  justify-content: center;
  font-family: "Roboto Slab", serif;
  background: white;
  border-bottom: 2px dashed #8994a0;
}

.header-image {
  width: 100%;
  height: 100%;
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(0, 0, 0, 1)),
    to(#fff9fa)
  );
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.header-text {
  justify-self: center;
  position: absolute;
  color: #fff9fa;
  text-shadow: 4px 3px 0 #555555;
  text-align: center;
}

.header-name {
  font-size: 125px;
  font-weight: bold;
}

.header-role {
  font-size: 75px;
  font-weight: bold;
}

@media (max-width: 576px) {
  .header-name {
    font-size: 50px;
  }
  .header-role {
    font-size: 35px;
  }
  .headshot-picture {
    margin-right: 0px;
  }
  .header-arrow {
    display: none;
  }
  /* 
  .header-image {
    height: 45%;
  } */
}

/* About Styling */

.about-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.headshot-picture {
  width: 200px;
  height: 200px;
  margin-right: 20px;
}

.description-container {
  width: 800px;
  padding-top: 20px;
}

/* Navigation Styling */

.nav-item {
  text-align: center;
}

/* Form Styling */

.form-container {
  display: flex;
  justify-content: center;
}

.form-width {
  width: 50vw;
  text-align: left;
}

.form-button {
  background-color: white !important;
  color: black !important;
  border: 1px solid black !important;
}

/* Projects */
