html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Roboto Slab", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Roboto Slab", serif;
}

a {
  color: #fff9fa !important;
}

a:hover {
  color: #8994a0 !important;
  text-decoration: none;
}
